// .options {
//   padding: 10px 20px;
//   &_isSelected {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     font-family: "HelveticaNeue";
//   }
// }

.DropdownArrow {
  transform: rotate(180deg);
  transition: 0.3s;
  &_isActive {
    transform: rotate(0deg);
    transition: 0.3s;
  }
}

@media only screen and (max-width: 992px) {
  .options {
    padding: 0px 10px;
  }
}
