@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

.wrapPage {
  // height: 100vh;
  // height: calc(var(--vh, 1vh)*100);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.startScrin {
  padding: 0 17.5%;
  display: flex;
  align-items: center;
  & .img {
    width: 100%;
    max-width: 715px;
    & img {
      width: 100%;
    }
  }
}

.info {
  max-width: 370px;
  margin-left: 75px;
}
.title {
  font-size: 50px;
  line-height: 54px;
  letter-spacing: 1px;
  color: #000000;
  margin-bottom: 15px;
  font-family: "Pegasus Regular", sans-serif;
}
.description {
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1.3px;
  color: #000000;
  font-family: 'Meison Neue Book', sans-serif;
}
.box_button {
  display: grid;
  align-items: center;
  width: 100%;
  gap: 25px;
  margin-top: 15px;

  & .btn{
    font-size: 11px;
  }
  & a {
    text-align: center;
    padding: 15.6px 0 11px 0;
    border: 1px solid #000000;
    box-sizing: border-box;
    transition: 0.3s;
    font-family: "Styrene-A";
    text-transform: uppercase;
    
    &:hover {
      background: #000000;
      transition: 0.3s;
      cursor: pointer;
      color: #fff;
    }
  }
}



@media  screen and (max-width: 1400px){
  .startScrin{
    padding: 0 1%;
  }
  .info {
    max-width: 370px;
    margin-left: 40px;
  }
  
}
@media  screen and (max-width: 1200px){

  .box_button{
    // margin-top: 50px;
  }
}

@media  screen and (max-width: 992px){
  .startScrin{
    flex-direction: column;
  }
  .info{
    margin-left: 0;
  }
  .title{
    margin-bottom: 0;
    margin-top: 20px;
  }
  
  .box_button{
    gap: 20px;
    // margin-top: 40px;
  }
}
@media screen and (max-width: 550px){
  .startScrin{
    flex-direction: column;
    padding: 0 ;
    
  }
  .info{
    margin: 0 16px;
  }
  .title {
    font-size: 24px;
    line-height: 28px; 
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .description {
    font-size: 12px;
    line-height: 15px;
  }
  .box_button{
    // margin-top: 70px;
    width: 100%;
    & .btn{
      font-size: 11px
    }
  }
}