.color_item {
  display: block;
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  transform: scale(1);
  
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    transition: 0.3s;
    border-radius: 50%;
  }
  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
  &.active {
    pointer-events: none;
    &:hover {
      transform: scale(1);
    }
    &::before {
      transform: translate(-50%, -50%) scale(1);
      transition: 0.3s;
      width: calc(100%);
      height: calc(100%);
      border: 1px solid #000000;
    }
  }

}
