@import url("fonts/style.css");

html {
  font-size: 16px;
  min-width: 280px;
  position: relative;
  line-height: 1.6;
  opacity: 1;
  margin: 0;
  z-index: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  -moz-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

body {
  overflow: hidden;
  margin: 0;
}
a {
  font-size: 16px;
  line-height: 1.2;
  text-align: right;
  text-decoration-line: underline;
  color: #000;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  font-family: "Styrene-A";
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    background: #e1e1e1;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
}

table,
td,
th {
  border: 1px solid black;
}

.buttons___2UtMn {
  display: none !important;
}

.defaultPlaceholder___3FCIV {
  display: none !important;
}