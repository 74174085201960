.item {
  &:first-child {
    margin-top: 0;
  }
  margin-top: 30px;
}
.itemBasket {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  & .img {
    max-width: 111px;
    margin-right: 17px;
    display: flex;
    align-items: center;
    & img {
      width: 100%;
    }
  }
  & .info {
    display: grid;
    width: 100%;
    position: relative;
    & .title {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #000000;
      margin-bottom: 6px;
      max-width: calc(100% - 15px);
      font-family: 'Pegasus Regular', sans-serif;
    }
    & .subtitle {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 11%;
      color: #898989;
      font-family:'Meison Neue Book', sans-serif;
      margin: 4px 0px;
    }
    & .price {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.11em;
      color: #000000;
      margin-bottom: 10px;
    }
    & .param_box {
      display: flex;
      align-items: center;
      position: relative;
      & .color {
        display: flex;
        align-items: center;
        margin-right: 17px;

        & .icon {
          width: 11px;
          height: 11px;
          background: #ebdc54;
          border-radius: 50%;
          margin-right: 9px;
          pointer-events: none;
          display: flex;
          align-items: center;
        }
        & .name {
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.11em;
          color: #898989;
          text-transform: uppercase;
        }
      }
      .carat {
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.11em;
        color: #000000;
      }
    }
    & .remote {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      right: 5px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .itemBasket {
    & .img {
      margin-right: 9px;
    }
  }
}
@media screen and (max-width: 380px) {
  .itemBasket {
    & .img {
      margin-right: 9px;
      max-width: 91px;
    }
  }
}
