
@font-face {
  font-family: "Jenriv";
  src: local("Styrene A Web Medium"), local("Styrene-A-Web-Medium"),
    url("Jenriv-Regular_DONATIONWARE.woff") format("woff");
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: 'Pegasus Regular';
  src: url('./Pegasus-RegulariKernOne.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Styrene-A";
  src:url("Styrene-A-Regular.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src:url("Styrene-A-Bold.woff") format('woff');
  font-weight: 600;
  font-style: bold;
}
@font-face {
  font-family: "Meison Neue Book";
  src:url("MaisonNeue-Book.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Meison Neue Medium";
  src:url("MaisonNeue-Medium.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Meison Neue Bold";
  src:url("MaisonNeue-Bold.woff") format('woff');
  font-weight: 600;
  font-style: bold;
}
