.btn {
  &_round {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    z-index: 2;
    & .btn {
      cursor: pointer;
      height: 44px;
      width: 44px;
      border-radius: 50px;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      min-height: 44px;
      transition: 0.3s;
      & svg {
        width: 100%;
        height: 100%;
        max-width: 12px;
        max-height: 12px;
      }
      &:hover {
        background-color: #000000;

        & svg path {
          fill: #fff;
        }
      }
    }
    & .text {
      cursor: pointer;
      line-height: 13px;
      letter-spacing: 11px;
      text-align: center;
      letter-spacing: 0.11em;
      color: #000000;
      margin-top: 10px;
      font-size: 11px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none;   /* Chrome/Safari/Opera */
      -khtml-user-select: none;    /* Konqueror */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Internet Explorer/Edge */
      user-select: none;           /* Non-prefixed version, currently not supported by any browser */
    }

    &_top {
      position: absolute;
      left: 50%;
      top: 10px;
      transform: translate(-50%, 0);
    }
    &_bottom {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
    }
  }
  &_arrow {
    z-index: 2;
    cursor: pointer;
    & svg {
      height: 48px;
      width: 24px;
      & path {
        stroke: #c0c0c0;
      }
    }
    &_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    &_right {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  &_reset,
  &_share,
  &_dropAhint {
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 11px;

    & .text{
      margin-top: 7px;
    }

  }
  &_share {
    position: absolute;
    right: 35px;
    top: 10px;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
  }
  &_reset {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &_dropAhint {
    position: absolute;
    right: 95px;
    top: 10px;
    & svg {
    }
  }
}
.MobileSwitchColors {
  display: none;
}

@media screen and(max-width:992px) {
  .none {
    display: none;
  }
  .btn {
    &_reset {
      z-index: 11;
      margin: 0 22px;
      // left: 5px;
      // bottom: -37px;
      & svg {
        width: 20px;
      }
      & .text {
        font-size: 8px;
      }
    }
    &_share {
      z-index: 11;
      margin: 0 22px;
      top: -33px;
      right: 5px;
      bottom: initial;
      & svg {
        width: 20px;
      }
      & .text {
        font-size: 8px;
      }
    }
    &_round {
      & .btn {
        display: none;
      }
      & .text {
        display: none;
      }
    }
    &_arrow {
      & svg {
        margin: 0 18px;
        height: 23px;
        width: 11px;
        & path {
          stroke: #000;
        }
      }
      &_right {
        right: 0px;
      }
    }
    &_dropAhint {
      z-index: 11;
      width: fit-content;
      top: -28px;
      // left: 25px;
      & svg {
        width: 20px;
      }
      & .text {
        font-size: 8px;
      }
    }
  }
  .MobileSwitchColors {
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -25px;
  }
  .tempBands {
    bottom: -60px;
    z-index: 13;
  }
  .hideMobileSwitchColors {
    display: none;
  }
}
@media screen and(max-width:460px) {
  .btn {
    &_reset {
      margin: 0 22px;
      left: -18px;
      & svg {
        height: 19px;
        width: 15.71px;
      }
    }
    &_share {
      margin: 0 11px;
      top: -42px;
      bottom: initial;
    }
    &_dropAhint {
      width: fit-content;
      top: -37px;
      right: 62px;
    }
  }
}
