.player_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #fff;
}
.player{
    height: inherit;
    width: inherit;
}
@media screen and(max-width:992px) {
    .player{
        height: 145%;
    } 
}

@media screen and(max-width:460px) {
    .player{
        height: 121%;
    } 
}

@media screen and(max-height: 750px) {
    .player{
        height: 132%;
    } 
}

@media screen and(max-height: 1400px) {
    .player{
        height: 109%;
    } 
}

@media screen and(max-height: 1100px) {
    .player{
        height: 111%;
    } 
}

@media screen and(max-height: 1100px) {
    .player{
        height: 111%;
    } 
}

@media screen and(max-height: 1024px) {
    .player{
        height: inherit;
    } 
}

@media screen and(max-height: 950px) {
    .player{
        height: 115%;
    } 
}

@media screen and(max-height: 800px) {
    .player{
        height: 101%;
    } 
}

@media screen and(max-height: 740px) {
    .player{
        height: 127%;
    } 
}

@media screen and(max-height: 690px) {
    .player{
        height: 133%;
    } 
}

@media screen and (max-height: 690px) and (width: 1280px) {
    .player{
        height: 100%;
    } 
}

@media screen and(max-height: 600px) {
    .player{
        height: 151%;
    } 
}

@media screen and(max-height: 590px) {
    .player{
        height: 151%;
    } 
}

@media screen and(max-height: 570px) {
    .player{
        height: 203%;
    } 
}

@media screen and(max-height: 550px) {
    .player{
        height: 180%;
    } 
}

@media screen and(max-height: 460px) {
    .player{
        height: 290%;
    } 
}


