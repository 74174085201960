.itemConfig {
  padding: 12px 5px 10px 5px;
  border: 1px solid transparent;
  position: relative;
  .mainItem {
    cursor: pointer;
  }
  & .icon_close {
    display: none;
    cursor: pointer;
  }
  & .img {
    width: 146px;
    height: 95px;
    margin: 0 auto;
    user-select: none;
    & img {
      user-select: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .info {
    margin-bottom: 16px;
    & .name {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #000000;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 9px;
      font-family: 'Pegasus Regular', sans-serif;
    }
    & .subname {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0.11em;
      color: #969696;
      text-align: center;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
      font-family: 'Maison Neue Book',sans-serif;
    }
    & .price {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0.11em;
      font-family: 'Meison Neue Book',sans-serif;
      color: #000000;
      text-align: center;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
      margin-top: 3px;
      font-family: 'Meison Neue Medium', sans-serif;
    }
  }
  & .config {
    display: grid;
    opacity: 0;
    pointer-events: none;
    gap: 17px;
    & .colors {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 98px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      bottom: 2px;
    }
    & .carats {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}

.itemConfig {
  &:hover {
    & .info {
      & .subname {
        opacity: 1;
        pointer-events: initial;
        transition: 0.3s;
        font-family: 'Maison Neue Book', sans-serif;
      }
      & .price {
        opacity: 1;
        pointer-events: initial;
        transition: 0.3s;
        margin-top: 3px;
      }
    }
  }
}
.itemConfig {
  &.active {
    height: 235px;
    width: 180px;
    border: 1px solid #000;

    & .icon_close {
      display: block;
      position: absolute;
      right: 5px;
      top: 5px;
      display: flex;
      align-items: center;
      z-index: 2;
    }

    &:hover {
      cursor: default;
    }
    cursor: pointer;
    & .config {
      opacity: 1;
      pointer-events: initial;
      transition: 0.3s;
    }
    & .info {
      & .subname {
        opacity: 1;
        pointer-events: initial;
        transition: 0.3s;
        font-family: 'Maison Neue Book', sans-serif;
        font-weight: normal;
      }
      & .price {
        opacity: 1;
        pointer-events: initial;
        transition: 0.3s;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .itemConfig:first-child {
    margin-left: 0;
  }
  .itemConfig {
    padding: 0;
    max-width: 135px;
    min-width: 134px;
    height: 163px;
    margin-left: 8px;
    & .img {
      width: 100%;
      height: 85px;
    }
    & .info {
      & .name {
        font-size: 11px;
        line-height: 11px;
        margin-bottom: 0px;
        letter-spacing: 0.4px;
      }
    }
    &.active {
      height: 163px;
      border: 1px solid #e7e7e7;
      border-bottom: none;
    }
    & .info {
      & .subname {
        text-transform: uppercase;
        opacity: 1;
        pointer-events: initial;
        font-size: 8px;
        line-height: 8px;
        transition: 0.3s;
        margin-top: 6px;
      }
      & .price {
        opacity: 1;
        pointer-events: initial;
        transition: 0.3s;
        font-size: 11px;
        line-height: 11px;
      }
    }
    & .config {
      display: none;
    }
  }
}
