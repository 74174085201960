.prev_button_content {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  & .icon {
    margin-right: 15px;
    width: 20px;
    display: flex;
    align-items: center;
    & svg {
      width: 100%;
    }
  }
  & .text {
    font-family: 'Pegasus Regular',sans-serif;
    letter-spacing: 1px;
    color: #000000;
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
  }
}
.basketWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .basketBox {
    height: calc(100% - 50px);
  }
}

.mainBasket {
  min-width: 445px;
  margin-top: 30px;
  height: calc(100% - 42px);
  & .boxBasket {
    display: flex;
    flex-direction: column;

    max-height: 560px;
    width: 100%;
    overflow: auto;
    margin-right: -12px;
    padding-right: 6px;
    padding-bottom: 10px;
    height: calc(100% - 110px);
  }
}

.footer_basket {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cbcbcb;
  & .size {
    display: grid;
    gap: 9px;
    min-width: 140px;
    & .name {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.11em;
      color: #000000;
    }
  }
  & .allPrice {
    display: flex;
    & .name {
      display: flex;
      align-items: center;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.11em;
      color: #000000;
      margin-right: 18px;
    }
    & .price {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.11em;
      color: #000000;
    }
  }
}

.btnClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000000;
  box-sizing: border-box;
  transition: 0.3s;
  width: 100%;
  background: #fff;
  & .box {
    min-width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #000;
    max-height: 50px;
    height: 100%;
    & .icon {
      display: flex;
      align-items: center;
    }
  }
  & .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 13px 27px;

    & svg {
      margin: 0 10px;
      & path {
        stroke: #000;
      }
    }
    & .name {
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.11em;
      color: #000000;
    }
  }
  &.disable {
    pointer-events: none;
    border: 1px solid #e7e7e7;
    color: #e7e7e7;
    & svg path {
      stroke: #e7e7e7;
    }
  }
  &:hover {
    border-color: #fff;
    background: #000000;
    transition: 0.3s;
    cursor: pointer;
    & svg {
      & path {
        stroke: #fff;
      }
    }
    & .info {
      & .name {
        color: #fff;
      }
    }
  }
  & .box {
    border-left: 1px solid #fff;
    & .price {
      margin-right: 15px;
    }
  }
}

.error {
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.11em;
  color: #cf4941;
}

@media screen and (max-width: 992px) {
  .mainBasket {
    min-width: 445px;
    margin-top: 5px;
  }
  .btnClass {
    padding: 0;
    font-size: 11px;
    & .box {
      display: flex;
      align-items: center;
      border-left: 1px solid #000;
      padding: 15px 0 30px 0;

      & .icon {
        position: relative;
        top: 7px;
      }
      & .price {
        margin-right: 40px;
      }
    }
    &:hover {
      border-color: #000000;
      background: #fff;
      transition: 0.3s;
      cursor: pointer;
      & svg {
        & path {
          stroke: #000000;
        }
      }
      & .info {
        & .name {
          color: #000000;
        }
      }
    }
  }

  .basketWrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 48px);
    background: #fff;
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .prew_button {
    min-height: 40px;
    padding: 16px;
  }
  .footer_basket {
    & .allPriceContainer {
      margin-bottom: auto;
    }
  }

  .basketWrap {
    & .basketBox {
      margin: 16px;
      height: calc(100% - 48px - 32px);
    }
  }
}

@media screen and (max-width: 768px) {
  .mainBasket {
    min-width: 220px;
  }
}
@media screen and (max-width: 350px) {
  .footer_basket {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
  }
}
