.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.3;
  margin-bottom: 17px;
  &_social {
    margin-top: 56px;
  }

}

.text{
  font-family:'Pegasus Regular' , sans-serif;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 1px;
}

.wrapShare {
  width: 100%;

  & input {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.11em;
    color: #a3a3a3;
    border: 1px solid #ffffff;
    padding: 10px 14px;
    background-color: #000;
    border-radius: 0px;
    margin-bottom: 37px;
    width: 100%;
  }
}

.btn {
  text-transform: uppercase;
  background: #ffffff;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 171px;
  width: 100%;
  padding: 13px;
  padding-top: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 157%;
  text-align: center;
  letter-spacing: 0.11em;
  margin: 0 auto;
  &_black {
    background-color: #000;
    color: #fff;
    transition: 0.3s;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  &_white {
    background-color: #fff;
    color: #000;
    transition: 0.3s;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
.done_saved {
  position: absolute;
  background: #fff;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 100%);
  bottom: 70px;
  padding: 20px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-top: none;
  cursor: pointer;
  z-index: -1;
  transition: 0.3s;
  & span {
    margin-left: 10px;
  }
  &_active {
    bottom: 0px;
    transition: 0.2s;
  }
}

.wrapSocial {
  & .box {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.social_item {
  width: 33px;
  height: 33px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and(max-width:992px) {
  .btn {
    max-width: 100%;
    width: 100%;
  }

  .text{
    width: 100%;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 1px;
  }
  .header {
    text-align: center;
    margin: 30px 22px 38px 23px;
  }
  .btn_white {
    margin-bottom: 42px;
  }
  .wrapShare {
    .inputContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & input {
        margin-bottom: 60px;
        width: 100%;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .wrapShare {
  
    & .header {
      margin: 26px 15px 30px 15px;
    }
    & .inputContainer {
      & input {
        margin-bottom: 30px;
      }
    }
    & .header_social {
      margin-top: 69px;
    }
  }
}
@media screen and(max-width:350px) {
  .wrapShare {
    max-width: 211px;

    & .header {
      margin-top: 49px;
    }
    & .wrapSocial{
      margin-bottom: 30px;
    }
  }
}
