.empty_screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  width: 100%;
  background: #fff;
}
.text {
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #dfdfdf;
  max-width: 192px;
}


@media screen and(max-width:300px) {
  .text{
    font-size: 16px;
  } 
}