.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  & header {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 1.27px;
    font-family: "Meison Neue Book", sans-serif;
  }
  & span{
    font-size: 28px;
    line-height: 32px;
    font-family: "Pegasus Regular", sans-serif;
  }
  & .main_section {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  & .select{
    flex-direction: column;

    & .btn_black{
      margin-right: 0;
      min-width: 262px;
      line-height: 15.32px;
      padding: 13px 6px;
      padding-top: 17px;
    }
    & .btn_white{
      margin-top: 10px;
      margin-left: 0;
      min-width: 262px;
      line-height: 15.32px;
    }
  }
}
.btn {
  text-transform: uppercase;
  background: #ffffff;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 13px;
  padding-top: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.11em;
  &_black {
    background-color: #000;
    color: #fff;
    transition: 0.3s;
    margin-right: 10px;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  &_white {
    background-color: #fff;
    margin-left: 10px;
    color: #000;
    transition: 0.3s;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
@media screen and(max-width:992px) {
  .modal {
    & header {
      margin-top: 7px;
    }
    & span{
      font-size: 22px;
      line-height: 22px;
    }
    & .main_section {
      flex-direction: column-reverse;

      & button {
        width: 100%;
      }
    }
  }
  .btn {
    max-width: 100%;
  }

  .btn_white {
    margin-left: 0;
  }
  .btn_black {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 62px;
    margin-right: 0;
  }
}
